


































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
import BaseForm from '@/components/forms/BaseForm.vue'
import { Form, KeyValue } from '@/store/commonFormInput/types'
import InputTypes from '@/common/formTypes'
import { Messages } from '@/store/user/types'
import { Action } from 'vuex-class'
import { SimpleServerResponse } from '@/store/types'
import { GET_RECIPIENT_GROUPS, SEND_MESSAGE } from '@/store/employee/actions.type'
import { hasRight } from '@/utils/rights'
import Rights from '@/common/rights'
@Component({
  components: { LoadingSpinner, BaseForm }
})
export default class MessagingSendMessages extends Vue {
  @Action(SEND_MESSAGE)
  public sendMessage: (data: Messages) => Promise<SimpleServerResponse>

  @Action(GET_RECIPIENT_GROUPS)
  public getRecipientGroups: () => Promise<SimpleServerResponse>

  isLoading = false
  sendMessagesFormData: Messages = { creatorEmail: '', creatorFullName: '', recipientGroup: '', subject: '', content: '' }
  recipientGroup: Array<string> = []

  sendMessageForm (): void {
    this.isLoading = true
    this.sendMessage(this.sendMessagesFormData).then(() => {
      this.$root.$emit('alert', this.$i18n.t('messaging.message-send'))
    }).catch(error => {
      this.$root.$emit('alert', this.$i18n.t('messaging.' + error.message), '', true)
    }).finally(() => {
      this.isLoading = false
      this.sendMessagesFormData = { creatorEmail: '', creatorFullName: '', recipientGroup: '', subject: '', content: '' }
    })
  }

  recipientGroups (): void {
    this.getRecipientGroups().then(data => {
      this.recipientGroup = data.content
    })
  }

  get groups (): KeyValue[] {
    return this.recipientGroup.map(key => {
      return {
        value: key,
        text: this.$i18n.t('messaging.recipients.' + key).toString()
      } as KeyValue
    })
  }

  get fields (): Form[] {
    return [
      {
        id: 'recipientGroup',
        key: 'recipientGroup',
        type: InputTypes.SELECT,
        required: true,
        label: this.$i18n.t('messaging.recipient-group').toString(),
        options: this.groups,
        autofocus: true,
        disabled: this.isLoading
      },
      {
        id: 'subject',
        key: 'subject',
        type: InputTypes.TEXT,
        required: true,
        label: this.$i18n.t('messaging.subject').toString(),
        disabled: this.isLoading
      },
      {
        id: 'content',
        key: 'content',
        type: InputTypes.TEXTAREA,
        required: true,
        label: this.$i18n.t('messaging.content').toString(),
        md: 6,
        disabled: this.isLoading
      }
    ]
  }

  get readonly (): boolean {
    return hasRight(Rights.MESSAGE_WRITE)
  }

  get writeable (): boolean {
    return hasRight(Rights.MESSAGE_WRITE)
  }

  mounted (): void {
    this.recipientGroups()
  }
}
